import { Injectable } from '@angular/core';
import { AppSyncResponse } from '@helpers/appSyncResponse.helper';
import { Service } from '@models/service.model';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';
import { ConfigService } from '../config/config.service';
import { LOCAL_DB_TABLE_NAME, LocalDbService } from '../local-db.service';
import { CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class ServiceApiService {
    private customerID;
    constructor(
        private appSyncService: AppSyncService,
        private localDbCache: LocalDbService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private readonly configService: ConfigService
    ) {
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }

    public async getServiceList(): Promise<Service[]> {
        const serviceList = (await this.localDbCache.get(LOCAL_DB_TABLE_NAME.service, 'serviceList')) as Service[];
        if (serviceList) {
            return serviceList;
        }
        const client = this.appSyncService.getClient(this.configService.config.serviceUrl);

        const getServiceListQuery = gql`
            query getServiceList($daySmartAccountID: ID!) {
                getServiceList(input: { daySmartAccountID: $daySmartAccountID }) {
                    serviceList {
                        id
                        name
                        categoryList
                        employeeIDList
                        resourceIDList
                        duration
                        lowestPrice
                        hasPriceException
                        depositRequired
                        depositPercentage
                        depositFixedAmount
                        webOrder
                    }
                }
            }
        `;
        this.navigationService.setIsLoading(true);
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getServiceListQuery,
            variables: { daySmartAccountID: this.customerID },
            fetchPolicy: 'no-cache',
        });
        const modelList = Service.deserializeAppSyncResponse(queryResult);
        void this.localDbCache.set(
            LOCAL_DB_TABLE_NAME.service,
            'serviceList',
            modelList,
            this.configService.config.serviceListCacheLifeTimeInSeconds
        );
        this.navigationService.setIsLoading(false);
        return modelList;
    }
}
