import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IntlModule, IntlService } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/en/all';
import { ServiceApiService } from '@services/api/service-api.service';
import { CustomDateAdapter } from '@services/custom-date-adapter';
import { NavigationService } from '@services/navigation.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app.md-module';
import { AddPetComponent } from './components/booking-steps/add-pet/add-pet.component';
import { AddServiceComponent } from './components/booking-steps/add-service/add-service.component';
import { EnterYourInformationComponent } from './components/booking-steps/enter-your-information/enter-your-information.component';
import { PetInformationComponent } from './components/booking-steps/pet-information/pet-information.component';
import { PetListComponent } from './components/booking-steps/pet-list/pet-list.component';
import { ReviewRequestComponent } from './components/booking-steps/review-request/review-request.component';
import { CustomCalendarHeaderComponent } from './components/booking-steps/select-date-time/custom-calendar-header/custom-calendar-header.component';
import { SelectDateTimeComponent } from './components/booking-steps/select-date-time/select-date-time.component';
import { SelectEmployeeComponent } from './components/booking-steps/select-employee/select-employee.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ContentComponent } from './components/content/content.component';
import { GeneralDialogComponent } from './components/dialog/general-dialog.component';
import { ErrorComponent } from './components/error/error.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { CreateAccountFormComponent } from './components/user-accounts/create-account-form/create-account-form.component';
import { LoginFormComponent } from './components/user-accounts/login-form/login-form.component';
import { HorizontalScrollDirective } from './directives/horizontal-scroll.directive';
import { SafePipe } from './pipes/safe.pipe';
import { AppSyncService } from './services/app-sync.service';
import { CheckTokensService } from './services/check-tokens.service';
import { ConfigService } from './services/config/config.service';
import { APP_CONFIG } from './services/config/constants';
import { LocaleService } from './services/locale.service';
import { ThemeService } from './services/theme.service';

export function playerFactory() {
    return import('lottie-web');
}

@NgModule({
    declarations: [
        AppComponent,
        TitleBarComponent,
        ContentComponent,
        AddServiceComponent,
        SelectEmployeeComponent,
        EnterYourInformationComponent,
        ReviewRequestComponent,
        ConfirmationComponent,
        PetInformationComponent,
        CustomCalendarHeaderComponent,
        ErrorComponent,
        HorizontalScrollDirective,
        SelectDateTimeComponent,
        LoginFormComponent,
        SafePipe,
        CreateAccountFormComponent,
        ConfirmationDialogComponent,
        GeneralDialogComponent,
        PetListComponent,
        AddPetComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppMaterialModule,
        LottieModule.forRoot({ player: playerFactory }),
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        IntlModule,
        MatDialogModule,
        RecaptchaV3Module,
    ],
    providers: [
        ConfigService,
        ThemeService,
        NavigationService,
        ServiceApiService,
        CheckTokensService,
        AppSyncService,
        { provide: DateAdapter, useClass: CustomDateAdapter },
        {
            provide: APP_INITIALIZER,
            useFactory: (themingService: ThemeService, configService: ConfigService) => () => {
                return themingService.initialize();
            },
            deps: [ThemeService, APP_CONFIG],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (checkTokensService: CheckTokensService, configService: ConfigService) => () => {
                return checkTokensService.performCheck();
            },
            deps: [CheckTokensService, APP_CONFIG],
            multi: true,
        },
        {
            provide: IntlService,
            useExisting: LocaleService,
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useFactory: (configService: ConfigService) => {
                return configService.config.reCaptchaSiteKey;
            },
            deps: [ConfigService, APP_CONFIG],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
