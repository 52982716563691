import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '@app/models/client.model';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root',
})
export class PosApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigService
    ) {}

    private get headers(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            secret_key: this.configService.config.posApiSecretKey,
        });
    }

    public getFromUserAccount(onlineId: string, customerId: string): Observable<any> {
        const url = `${this.configService.config.posApiUrl}/Client/GetFromUserAccount`;
        const body = {
            OnlineAccountGuid: onlineId,
            CustomerId: +customerId,
        };
        return this.httpClient.post(url, body, { headers: this.headers });
    }

    public associateUserAccount(customerId: string, onlineId: string, client: Client): Observable<any> {
        const url = `${this.configService.config.posApiUrl}/Client/AssociateUserAccount`;
        const OptInStatus = {
            OptedIn: 2,
            OptedOut: 3,
        } as const;
        const body = {
            CustomerId: +customerId,
            Client: {
                OnlineID: onlineId,
                FirstName: client.firstName,
                LastName: client.lastName,
                CellPhone: client.phoneNumber,
                Email: client.email,
                SendMarketingEmails: client.enableEmailMarketing,
                OptInStatus: client.enableTextMarketing ? OptInStatus.OptedIn : OptInStatus.OptedOut,
            },
        };
        return this.httpClient.post(url, body, { headers: this.headers });
    }
}
