import { Injectable } from '@angular/core';
import { ButtonName } from '@app/components/content/content.component';
import { Client } from '@app/models/client.model';
import { Pet } from '@app/models/pet.model';
import { Site } from '@app/models/site.model';
import { UserAccount } from '@app/models/userAccount.model';
import { ErrorDialogService } from '@app/services/error-dialog.service';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ClientApiService } from './api/client-api.service';
import { PosApiService } from './api/pos-api.service';
import { UserAccountApiService } from './api/user-account-api.service';
import { AuthTokenService } from './auth-token.service';
import { ConfigService } from './config/config.service';
import { ContentService } from './content.service';
import { FeatureFlagService, FeatureKey } from './feature-flag.service';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, ONLINE_ID, PET_LIST, SITE } from './local-storage.service';
import { NavigationService } from './navigation.service';
import { PetService } from './pet.service';

@Injectable({
    providedIn: 'root',
})
export class UserAccountService {
    private userAccount: UserAccount | undefined;
    private readonly _userAccountUpdated = new Subject<UserAccount | undefined>();
    private site: Site | undefined;
    private userAccountFeatureEnabled: boolean = true;
    constructor(
        private userAccountApiService: UserAccountApiService,
        private posApiService: PosApiService,
        private authTokenService: AuthTokenService,
        private localStorageService: LocalStorageService,
        private errorDialogService: ErrorDialogService,
        private clientApiService: ClientApiService,
        private featureFlagService: FeatureFlagService,
        private petService: PetService,
        private navigationService: NavigationService,
        private contentService: ContentService,
        private readonly configService: ConfigService
    ) {}

    public userAccountUpdated$ = this._userAccountUpdated.asObservable();

    public login(idToken: string, accessToken: string, refreshToken: string, customerId: string): Observable<boolean> {
        const cidInIdToken = this.authTokenService.getCustomerIdFromIdToken(idToken).toString();
        if (cidInIdToken !== customerId) {
            this.errorDialogService.openErrorDialog('Customer ID in ID token does not match the expected customer ID');
            return of(false);
        }
        this.authTokenService.saveAllTokensToCookie(idToken, accessToken, refreshToken);
        const onlineId = this.authTokenService.getOnlineIdFromIdToken(idToken);
        this.UpdateClientOnlineID(onlineId);
        const email = this.authTokenService.getEmailFromIdToken(idToken);
        const client = new Client();
        client.email = email;
        const CID = this.localStorageService.getString(CUSTOMER_ID);
        this.navigationService.setIsLoading(true);

        const clientApiCall$ = this.featureFlagService.getFeatureFlag(FeatureKey.PosDsbGroupSync)
            ? this.clientApiService.getClient(CID, onlineId).pipe(
                  tap((response) => {
                      if (!response.client) {
                          this.UpdateUserAccount('', '', email, onlineId);
                      } else {
                          client.firstName = response.client.firstName;
                          client.lastName = response.client.lastName;
                          client.phoneNumber = response.client.cell;
                          this.UpdateUserAccount(response.client.firstName, response.client.lastName, email, onlineId);
                      }
                      this.UpdateApptRequest(client);
                  }),
                  catchError(() => {
                      this.UpdateUserAccount('', '', email, onlineId);
                      this.UpdateApptRequest(client);
                      return of(null);
                  })
              )
            : this.posApiService.getFromUserAccount(onlineId, customerId).pipe(
                  tap((response) => {
                      if (!response.Client) {
                          this.UpdateUserAccount('', '', email, onlineId);
                      } else {
                          client.firstName = response.Client.FirstName;
                          client.lastName = response.Client.LastName;
                          client.phoneNumber = response.Client.CellPhone;
                          client.enableEmailMarketing = response.Client.SendMarketingEmails;
                          client.enableTextMarketing = response.Client.OptInStatus === 2;
                          this.UpdateUserAccount(response.Client.FirstName, response.Client.LastName, email, onlineId);
                      }
                      this.UpdateApptRequest(client);
                  }),
                  catchError(() => {
                      this.UpdateUserAccount('', '', email, onlineId);
                      this.UpdateApptRequest(client);
                      return of(null);
                  })
              );

        return clientApiCall$.pipe(
            switchMap(() => this.getOrUpdatePetListInLocalStorage(CID, onlineId)),
            map(() => true)
        );
    }

    public logout() {
        this.userAccount = undefined;
        this._userAccountUpdated.next(this.userAccount);
        this.authTokenService.clearAllCookies();
        this.petService.clearPetList();
    }

    public create(client: Client, password: string, customerId: string): Observable<any> {
        const CID = this.localStorageService.getString(CUSTOMER_ID);

        return this.userAccountApiService.createUserAccount(client.email, password, customerId).pipe(
            switchMap((res) => {
                const tokens = res.tokens;
                this.authTokenService.saveAllTokensToCookie(tokens.idToken, tokens.accessToken, tokens.refreshToken);
                this.UpdateUserAccount(client.firstName, client.lastName, client.email, res.onlineId);
                this.UpdateApptRequest(client);
                this.saveTokensToClientAuthFrontendCookies(tokens);

                // Associate user account and get pet list
                const associateUser$ = this.posApiService.associateUserAccount(customerId, res.onlineId, client).pipe(
                    catchError(() => {
                        return of(undefined);
                    })
                );

                const getPetList$ = this.getOrUpdatePetListInLocalStorage(CID, res.onlineId);

                // Combine both observables
                return combineLatest([associateUser$, getPetList$]).pipe(map(() => true));
            }),
            catchError((createError) => {
                throw createError;
            })
        );
    }

    public getCurrentUser(): UserAccount | undefined {
        return this.userAccount;
    }

    public handleAccountCreation(site: Site, selectedStepIndex: number, navigateToRoute: (selectedStep: any) => void) {
        const storedPetListData = this.localStorageService.get(PET_LIST) as Pet[];
        const hasPetListInfo = storedPetListData?.length > 0;
        this.contentService.isAccountCreatedFromClientScreen = true;
        if (selectedStepIndex === this.contentService.steps?.find((x) => x.stepRoute === 'booking/client')?.index) {
            if (!site?.supportsPets) {
                this.contentService.stepper?.next();
                this.contentService.selectedStepIndex = this.contentService.stepper?.selectedIndex;
                const selectedStep = this.contentService.steps?.find((x) => x.buttonText === ButtonName.SubmitRequest);
                this.contentService.buttonName = selectedStep?.buttonText ?? this.contentService.steps[0].buttonText;
                navigateToRoute(selectedStep);
            } else {
                this.setPetScreenNavigation(hasPetListInfo);
            }
        } else if (
            this.contentService.selectedStepIndex === this.contentService.steps?.find((x) => x.stepRoute === 'booking/datetime')?.index
        ) {
            if (site?.supportsPets) {
                this.setPetScreenNavigation(hasPetListInfo);
            }
        }
    }

    private setPetScreenNavigation(hasPetListInfo: boolean) {
        if (hasPetListInfo) {
            this.contentService.sendData('ScreenThree');
        } else {
            this.contentService.sendData('ScreenTwo');
        }
    }

    private UpdateUserAccount(firstName: string, lastName: string, email: string, onlineId: any) {
        this.userAccount = new UserAccount(firstName, lastName, email, onlineId);
        this._userAccountUpdated.next(this.userAccount);
    }

    private UpdateApptRequest(client: Client) {
        this.localStorageService.setNested(APPOINTMENT_REQUEST, 'client', client);
    }

    private UpdateClientOnlineID(onlineID: any) {
        this.localStorageService.setString(ONLINE_ID, onlineID);
    }

    private saveTokensToClientAuthFrontendCookies(tokens: any) {
        const origin = this.configService.config.authService;
        const url = `${origin}/UpdateTokens`; // Client Auth URL which routes to UpdateTokens
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);

        const handleMessage = (event: MessageEvent) => {
            if (event?.origin === origin && event?.data?.result === 'success' && event?.data?.data === 'Ready') {
                // Once the "Ready" message is received, send tokens to the iframe to save to cookies
                iframe.contentWindow?.postMessage(tokens, url);
                // Remove the event listener after the message is posted
                window.removeEventListener('message', handleMessage);
            }
        };

        // Add an event listener to listen "success" and "Ready" message from iframe
        window.addEventListener('message', handleMessage);
    }

    private getOrUpdatePetListInLocalStorage(CID: string, onlineId: any): Observable<void> {
        const site = this.localStorageService.get(SITE) as Site;
        const supportsPets = site?.supportsPets;
        if (supportsPets && this.featureFlagService.getFeatureFlag(FeatureKey.UserAccounts)) {
            return this.petService.getPetList(CID, onlineId, true).pipe(
                tap(() => this.navigationService.setIsLoading(false)),
                map(() => undefined as void),
                catchError(() => {
                    this.navigationService.setIsLoading(false);
                    return of(undefined as void);
                })
            );
        } else {
            this.navigationService.setIsLoading(false);
            return of(undefined as void);
        }
    }
}
