import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from '@app/services/config/config.service';
import { ContentService } from '@app/services/content.service';
import { CUSTOMER_ID, LocalStorageService } from '@app/services/local-storage.service';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string; description: string },
        private contentService: ContentService,
        private localStorageService: LocalStorageService,
        private readonly configService: ConfigService
    ) {}

    onCancel(): void {
        this.dialogRef.close();
    }

    onLogout(): void {
        this.contentService.sendData(this.contentService.logout);
        this.dialogRef.close();
        this.clearSSOAuthTokensFromCookies();
    }

    private clearSSOAuthTokensFromCookies() {
        const origin = this.configService.config.authService;
        const customerID = this.localStorageService.getString(CUSTOMER_ID);
        const customerIdWithoutPrefix = customerID?.replace(/.*?(\d+)/, '$1'); //NOSONAR
        const url = `${origin}?customerID=${customerIdWithoutPrefix}&removeExistingTokens=1`;
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
    }
}
