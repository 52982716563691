import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root',
})
export class UserAccountApiService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly configService: ConfigService
    ) {}

    private userGroup = 'UserAccounts';
    private getHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': this.configService.config.clientAuthServiceApiKey,
        });
    }

    public createUserAccount(email: string, password: string, customerId: string): Observable<any> {
        const url = `${this.configService.config.clientAuthServiceApiUrl}/signUp`;
        const body = {
            email: email,
            password: password,
            customerId: +customerId,
            userGroup: this.userGroup,
        };
        return this.httpClient.post(url, body, { headers: this.getHeaders() });
    }
}
