<!-- eslint-disable max-len -->
<div *ngIf="!disabled" class="step-container">
    <div *ngIf="!showAddPetTitle" class="header-container">
        <h1 qa="client-pet-information-title" class="step-header-main">
            Client & Pet Information <span class="mini-step-header"> 2 of 2</span>
        </h1>
        <h2 class="step-header-secondary">All fields are required.</h2>
    </div>
    <form class="form-container" [formGroup]="formGroup">
        <div class="form-field">
            <label>
                Pet Name
                <span>*</span>
            </label>
            <mat-form-field>
                <input matInput qa="pet-name" name="name" autocomplete="turnoff" formControlName="name" maxlength="80" />
            </mat-form-field>
        </div>
        <div class="form-field">
            <label>
                Pet Type
                <span>*</span>
            </label>
            <mat-form-field [floatLabel]="'auto'">
                <mat-select
                    placeholder="Select a Pet Type..."
                    id="petType"
                    [compareWith]="comparePetTypeOptions"
                    (selectionChange)="petTypeChange()"
                    formControlName="petType"
                    qa="pet-type"
                >
                    <mat-option qa="pet-type-name" *ngFor="let petType of petTypeList" [value]="petType">
                        {{ petType.name }}
                    </mat-option>
                </mat-select>
                <svg class="dropdown-button" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.66391 0.294922L6.25391 4.87492L10.8439 0.294922L12.2539
                             1.70492L6.25391 7.70492L0.253906 1.70492L1.66391 0.294922Z"
                        fill="none"
                    />
                </svg>
            </mat-form-field>
        </div>
        <div class="form-field" *ngIf="petBreedList.length > 0">
            <label>
                Pet Breed
                <span>*</span>
            </label>
            <mat-form-field [floatLabel]="'auto'">
                <mat-select
                    placeholder="Select a Pet Breed..."
                    id="petBreed"
                    formControlName="petBreed"
                    [compareWith]="comparePetBreedOptions"
                    qa="pet-breed"
                >
                    <mat-option qa="pet-breed-name" *ngFor="let petBreed of petBreedList" [value]="petBreed">
                        {{ petBreed.name }}
                    </mat-option>
                </mat-select>
                <svg class="dropdown-button" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.66391 0.294922L6.25391 4.87492L10.8439 0.294922L12.2539
                             1.70492L6.25391 7.70492L0.253906 1.70492L1.66391 0.294922Z"
                        fill="#3C6CE7"
                    />
                </svg>
            </mat-form-field>
        </div>
        <div class="form-field">
            <label>
                Weight
                <span>*</span>
            </label>
            <mat-form-field [floatLabel]="'auto'">
                <mat-select required placeholder="Select a weight range..." formControlName="weight" name="weight" qa="pet-weight">
                    <mat-option qa="pet-select-weight" value="0 – 20 lbs">0 – 20 lbs</mat-option>
                    <mat-option qa="pet-select-weight" value="21 – 45 lbs">21 – 45 lbs</mat-option>
                    <mat-option qa="pet-select-weight" value="46 – 75 lbs">46 – 75 lbs</mat-option>
                    <mat-option qa="pet-select-weight" value="76 or more lbs">76 or more lbs</mat-option>
                </mat-select>
                <svg class="dropdown-button" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.66391 0.294922L6.25391 4.87492L10.8439 0.294922L12.2539
                             1.70492L6.25391 7.70492L0.253906 1.70492L1.66391 0.294922Z"
                        fill="#3C6CE7"
                    />
                </svg>
            </mat-form-field>
        </div>
    </form>
</div>
