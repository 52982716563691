import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG, CONFIG_URL } from './app/services/config/constants';

function configListener(this: XMLHttpRequest) {
    try {
        const configuration = JSON.parse(this.responseText);
        if (configuration?.production) {
            enableProdMode();
        }

        // pass config to bootstrap process using an injection token
        platformBrowserDynamic([{ provide: APP_CONFIG, useValue: configuration }])
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));
    } catch (error) {
        console.error(error);
    }
}

function configFailed(evt: any) {
    console.error('Error: retrieving config.json', evt);
}
const request = new XMLHttpRequest();
request.addEventListener('load', configListener);
request.addEventListener('error', configFailed);
request.open('GET', CONFIG_URL);
request.send();
