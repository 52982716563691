import { Injectable } from '@angular/core';
import { CreditCard, ValidateCreditCardRequest } from '@app/models/creditCard.model';
import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable, from, map } from 'rxjs';
import { AppSyncService } from '../app-sync.service';
import { ConfigService } from '../config/config.service';
import { CUSTOMER_ID, LocalStorageService } from '../local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class CreditCardApiService {
    private customerID;
    constructor(
        private appSyncService: AppSyncService,
        private localStorageService: LocalStorageService,
        private readonly configService: ConfigService
    ) {
        this.customerID = this.localStorageService.getString(CUSTOMER_ID);
    }

    validateCreditCard(creditCard: CreditCard): Observable<boolean> {
        const client = this.appSyncService.getClient(this.configService.config.serviceUrl);
        creditCard.daySmartAccountID = this.customerID;
        const request = new ValidateCreditCardRequest(creditCard);
        const validateCreditCardQuery = gql`
            query validateCreditCard($creditCard: ValidateCreditCardRequest!) {
                validateCreditCard(input: $creditCard) {
                    passedAuthorization
                }
            }
        `;
        return from(
            client.query({
                query: validateCreditCardQuery,
                variables: { creditCard: request },
                fetchPolicy: 'no-cache',
            })
        ).pipe(
            map((queryResult) => {
                return CreditCard.deserializeValidateCreditCardResponse(queryResult as ApolloQueryResult<AppSyncResponse>);
            })
        );
    }
}
