import { Injectable } from '@angular/core';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { SyncAppointmentRequestDataRequest, SyncAppointmentRequestDataResponse } from '@app/models/syncAppointmentRequest.model';
import gql from 'graphql-tag';
import { AppSyncService } from '../app-sync.service';
import { ConfigService } from '../config/config.service';

import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { ApolloQueryResult } from 'apollo-client';
import { Observable, from, map } from 'rxjs';
import { AppointmentRequestService } from '../appointment-request.service';
import { CreditCardService } from '../credit-card.service';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService } from '../local-storage.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class AppointmentRequestApiService {
    constructor(
        private appSyncService: AppSyncService,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private creditCardService: CreditCardService,
        private readonly configService: ConfigService
    ) {}

    async createAppointmentRequest(
        appointmentRequestService: AppointmentRequestService,
        token: string
    ): Promise<SyncAppointmentRequestDataResponse | undefined> {
        const client = this.appSyncService.getClient(this.configService.config.serviceUrl);

        const apptRequestFromLocalStorage = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        const daySmartAccountID = this.localStorageService.getString(CUSTOMER_ID);
        const creditCard = this.creditCardService.getCreditCardInformation();
        if (creditCard !== undefined) {
            this.creditCardService.setCreditCardInformation(undefined);
        }
        const syncAppointmentRequestDataRequest =
            SyncAppointmentRequestDataRequest.convertLocalStorageApptRequestToSyncApptRequestDataRequest(
                apptRequestFromLocalStorage,
                daySmartAccountID,
                appointmentRequestService,
                token,
                creditCard
            );
        const createAppointmentRequestMutation = gql`
            mutation syncAppointmentRequest($appointmentRequest: SyncApptRequestDataRequest!) {
                syncAppointmentRequest(input: $appointmentRequest) {
                    newValue {
                        client {
                            firstName
                            lastName
                            email
                        }
                        id
                        ticketID
                        itemList {
                            employeeID
                            serviceID
                        }
                        startDateTime
                    }
                }
            }
        `;

        const mutationResult = await client.mutate({
            mutation: createAppointmentRequestMutation,
            variables: { appointmentRequest: syncAppointmentRequestDataRequest },
        });
        const response = AppointmentRequest.deserializeAppSyncResponse(mutationResult);
        return response;
    }

    getAppointmentRequest(requestID: string): Observable<number | undefined> {
        const client = this.appSyncService.getClient(this.configService.config.serviceUrl);

        const daySmartAccountID = this.localStorageService.getString(CUSTOMER_ID);

        const getAppointmentRequestQuery = gql`
            query getAppointmentRequest($daySmartAccountID: ID!, $id: String!) {
                getAppointmentRequest(input: { daySmartAccountID: $daySmartAccountID, id: $id }) {
                    appointmentRequest {
                        ticketID
                    }
                }
            }
        `;

        return from(
            client.query({
                query: getAppointmentRequestQuery,
                variables: { daySmartAccountID: daySmartAccountID, id: requestID },
                fetchPolicy: 'no-cache',
            })
        ).pipe(
            map((queryResult) => {
                return AppointmentRequest.deserializeGetAppointmentRequestResponse(queryResult as ApolloQueryResult<AppSyncResponse>);
            })
        );
    }
}
