import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root',
})
export class ClientApiService {
    constructor(
        private http: HttpClient,
        private readonly configService: ConfigService
    ) {}

    private get apiUrl(): string {
        return this.configService.config.dsbApiUrl;
    }

    private get headers(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': this.configService.config.dsbApiSecretKey,
        });
    }

    getClient(daySmartAccountId: string, onlineID: string): Observable<any> {
        const url = `${this.apiUrl}/api/client/${daySmartAccountId}/${onlineID}`;
        return this.http.get<any>(url, { headers: this.headers });
    }
}
