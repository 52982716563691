import { Injectable } from '@angular/core';
import { ConfigService } from './config/config.service';
import { CUSTOMER_ID, LocalStorageService } from './local-storage.service';
import { UserAccountService } from './user-account.service';

@Injectable({
    providedIn: 'root',
})
export class CheckTokensService {
    constructor(
        private userAccountService: UserAccountService,
        private localStorageService: LocalStorageService,
        private readonly configService: ConfigService
    ) {}

    public async performCheck(): Promise<any> {
        return new Promise((resolve) => {
            const customerID = this.localStorageService.getString(CUSTOMER_ID);
            // Below NOSONAR comment is to escape from SonarCloud Code Analysis
            const customerIdWithoutPrefix = customerID?.replace(/.*?(\d+)/, '$1'); //NOSONAR
            if (!customerIdWithoutPrefix) {
                resolve('error');
            } else {
                const origin = this.configService.config.authService;
                // Client Auth URL which routes to CheckTokens with customer id as route parameter
                const url = `${origin}/CheckTokens/${customerIdWithoutPrefix}`;
                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = url;
                document.body.appendChild(iframe);

                const handleMessage = (event: MessageEvent) => {
                    if (event?.origin === origin) {
                        const { result, data } = event?.data;
                        if (result === 'success' && data?.idToken && data?.accessToken && data?.refreshToken) {
                            this.userAccountService
                                .login(data.idToken, data.accessToken, data.refreshToken, customerIdWithoutPrefix)
                                .subscribe({
                                    complete: () => {
                                        // Once successfully completed, Remove iframe and listener
                                        this.removeHandlers(iframe, handleMessage);
                                        resolve(result);
                                    },
                                });
                        } else {
                            this.removeHandlers(iframe, handleMessage);
                            resolve(result);
                        }
                    }
                };

                window.addEventListener('message', handleMessage);
            }
        });
    }

    private removeHandlers(iframe: HTMLIFrameElement, handleMessage: (event: MessageEvent) => void) {
        window.removeEventListener('message', handleMessage);
        document.body.removeChild(iframe);
    }
}
